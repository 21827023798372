.subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.subtitle.s1 {
  font-size: 15px;
} 

.subtitle.s2 {
  font-size: 13px;
} 

.paragraph {
  font-style: normal;
  font-weight: 400;
}

.paragraph.p1 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.paragraph.p2 {
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
}

.caption {
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

.caption.c1 {
  font-weight: normal;
}

.caption.c2 {
  font-weight: 600;
}

.label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}