.FileSelector {
}

.FileSelectorDropZone {
  background: var(--color-ash-200);
  border: 2px dashed var(--color-ash-500);
  box-sizing: border-box;
  border-radius: 4px;
}

.FileSelectorContainer {
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FileSelectorContainer > img {
  margin-bottom: 16px;
}

.FileSelectorContainer > button {
  margin-bottom: 8px;
}

.FileSelectorDropZone.dragActive {
  border-color: var(--color-brand-200);
}
.FileSelectorDropZone.dragActive > .FileSelectorContainer {
  opacity: 0.3;
}
