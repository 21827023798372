.AppLayout {
  
}

.AppHeader {
  background-color: var(--color-brand-300);
  padding: 16px;
}

.AppLogo,
.AppLogo:hover,
.AppLogo:visited,
.AppLogo:link,
.AppLogo:active
{
  font-family: 'Ubuntu';
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-decoration: none;
}

.AppContainer {
  padding: 122px 0;
}