.FilePreview {
  width: 50%;
  background: #F5F5F5;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FilePreview.success,
.FilePreview.error,
.FilePreview.loading {
  padding-bottom: 24px;
}

.FilePreview.error {
  border: 1px dashed var(--color-danger-300);
  border-radius: 4px;
}

.FilePreview.success {
  border: 1px dashed var(--color-success-300);
  border-radius: 4px;
}

.FilePreviewContent {
  flex-grow: 1;
}

.FilePreviewContent .lastModified {
  color: var(--color-dark-500);
}

.FilePreviewContent .errorMessage {
  color: var(--color-danger-300);
}

.FilePreviewContent .successMessage {
  color: var(--color-success-300);
}

.FilePreviewContent .loadingMessage {
  color: var(--color-brand-300);
}

.FilePreviewCancelButton {
  padding: 0;
  width: 16px;
  height: 16px;
  background: url('../../../resources/img/cancel.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 0;
  border: none;
}
