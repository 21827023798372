@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto&family=Ubuntu:wght@500&display=swap);
:root {
  --color-transparent: transparent;

  --color-dark-100: #000000;
  --color-dark-200: #262626;
  --color-dark-300: #434343;
  --color-dark-400: #555555;
  --color-dark-500: #7B7B7B;

  --color-ash-100: #FFFFFF;
  --color-ash-200: #F5F5F5;
  --color-ash-300: #E9E9E9;
  --color-ash-400: #D9D9D9;
  --color-ash-500: #C4C4C4;

  --color-brand-100: #E5CAF3;
  --color-brand-200: #5A3EAF;
  --color-brand-300: #3A0CA3;
  --color-brand-400: #271891;
  --color-brand-500: #000A67;

  --color-success-100: #E4F4F5;
  --color-success-200: #C1EBEB;
  --color-success-300: #69BEBD;
  --color-success-400: #37828C;
  --color-success-500: #103034;

  --color-warning-100: #F9EBDC;
  --color-warning-200: #F7C693;
  --color-warning-300: #E89753;
  --color-warning-400: #B45C2B;
  --color-warning-500: #441809;

  --color-danger-100: #F8CDBE;
  --color-danger-200: #FF8162;
  --color-danger-300: #D82400;
  --color-danger-400: #9E1B00;
  --color-danger-500: #4F0E00;

  --color-info-100: #EDF5F9;
  --color-info-200: #BCDFF7;
  --color-info-300: #2A6FB5;
  --color-info-400: #1E4E86;
  --color-info-500: #031427;
}
/**
 * Import fonts(weights):
 *  - Poppins(400, 500, 600)
 *  - Roboto(400)
*/

h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 1px;
}

h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 1px;
  color: #262626;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-theme-800)
}

.AppLayout_AppLayout__3r2pF {
  
}

.AppLayout_AppHeader__dhlQ6 {
  background-color: var(--color-brand-300);
  padding: 16px;
}

.AppLayout_AppLogo__38_ho,
.AppLayout_AppLogo__38_ho:hover,
.AppLayout_AppLogo__38_ho:visited,
.AppLayout_AppLogo__38_ho:link,
.AppLayout_AppLogo__38_ho:active
{
  font-family: 'Ubuntu';
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-decoration: none;
}

.AppLayout_AppContainer__2irb8 {
  padding: 122px 0;
}
:root {
  --color-transparent: transparent;

  --color-dark-100: #000000;
  --color-dark-200: #262626;
  --color-dark-300: #434343;
  --color-dark-400: #555555;
  --color-dark-500: #7B7B7B;

  --color-ash-100: #FFFFFF;
  --color-ash-200: #F5F5F5;
  --color-ash-300: #E9E9E9;
  --color-ash-400: #D9D9D9;
  --color-ash-500: #C4C4C4;

  --color-brand-100: #E5CAF3;
  --color-brand-200: #5A3EAF;
  --color-brand-300: #3A0CA3;
  --color-brand-400: #271891;
  --color-brand-500: #000A67;

  --color-success-100: #E4F4F5;
  --color-success-200: #C1EBEB;
  --color-success-300: #69BEBD;
  --color-success-400: #37828C;
  --color-success-500: #103034;

  --color-warning-100: #F9EBDC;
  --color-warning-200: #F7C693;
  --color-warning-300: #E89753;
  --color-warning-400: #B45C2B;
  --color-warning-500: #441809;

  --color-danger-100: #F8CDBE;
  --color-danger-200: #FF8162;
  --color-danger-300: #D82400;
  --color-danger-400: #9E1B00;
  --color-danger-500: #4F0E00;

  --color-info-100: #EDF5F9;
  --color-info-200: #BCDFF7;
  --color-info-300: #2A6FB5;
  --color-info-400: #1E4E86;
  --color-info-500: #031427;
}
.Button_button__1EPUx {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  line-height: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: var(--color-ash-100);
  display: inline-flex;
  align-items: center;
  justify-content : center;
  border: 1px solid var(--color-transparent);
}

.Button_sm__34DNu {
  min-width: 89px;
  height: 32px;
}
.Button_md__2wPOt {
  min-width: 107px;
  height: 40px;
}
.Button_lg__2s4b2 {
  min-width: 89px;
  height: 48px;
}

/* default brand */
.Button_button__1EPUx.Button_brand__2cXYe {
  background-color: var(--color-brand-300);
}
/* brand button hover, active, focus */
.Button_button__1EPUx.Button_brand__2cXYe:hover, 
.Button_button__1EPUx.Button_brand__2cXYe.Button_active__2tsBs, 
.Button_button__1EPUx.Button_brand__2cXYe:focus {
  background-color: var(--color-brand-200);
}
/* default brand outlined */
.Button_button__1EPUx.Button_brand__2cXYe.Button_outlined__3-e93 {
  color: var(--color-brand-300);
  border-color: var(--color-brand-300);
}
/* brand button outlined hover, active, focus */
.Button_button__1EPUx.Button_brand__2cXYe.Button_outlined__3-e93:hover,
.Button_button__1EPUx.Button_brand__2cXYe.Button_outlined__3-e93.Button_active__2tsBs, 
.Button_button__1EPUx.Button_brand__2cXYe.Button_outlined__3-e93:focus {
  color: var(--color-ash-100);
  background-color: var(--color-brand-300);
}

/* default success */
.Button_button__1EPUx.Button_success__2S1ky {
  background-color: var(--color-success-400);
}
/* success button hover, active, focus */
.Button_button__1EPUx.Button_success__2S1ky:hover,
.Button_button__1EPUx.Button_success__2S1ky.Button_active__2tsBs,
.Button_button__1EPUx.Button_success__2S1ky:focus {
  background-color: var(--color-success-300);
}
/* default success outlined */
.Button_button__1EPUx.Button_success__2S1ky.Button_outlined__3-e93 {
  color: var(--color-success-400);
  border-color: var(--color-success-400);
}
/* success outlined hover, active, focus */
.Button_button__1EPUx.Button_success__2S1ky.Button_outlined__3-e93:hover,
.Button_button__1EPUx.Button_success__2S1ky.Button_outlined__3-e93.Button_active__2tsBs, 
.Button_button__1EPUx.Button_success__2S1ky.Button_outlined__3-e93:focus {
  color: var(--color-ash-100);
  border-color: var(--color-transparent);
  background-color: var(--color-success-400);
} 

/* default danger */
.Button_button__1EPUx.Button_danger__M1jKp {
  background-color: var(--color-danger-300);
}
/* danger hover, active, focus */
.Button_button__1EPUx.Button_danger__M1jKp:hover, 
.Button_button__1EPUx.Button_danger__M1jKp.Button_active__2tsBs, 
.Button_button__1EPUx.Button_danger__M1jKp:focus {
  background-color: var(--color-danger-200);
}
/* default danger outlined */
.Button_button__1EPUx.Button_danger__M1jKp.Button_outlined__3-e93 {
  color: var(--color-danger-300);
  border-color: var(--color-danger-300);
}
/* danger outlined hover, active, focus */
.Button_button__1EPUx.Button_danger__M1jKp.Button_outlined__3-e93:hover, 
.Button_button__1EPUx.Button_danger__M1jKp.Button_outlined__3-e93.Button_active__2tsBs, 
.Button_button__1EPUx.Button_danger__M1jKp.Button_outlined__3-e93:focus {
  color: var(--color-ash-100);
  border-color: var(--color-transparent);
  background-color: var(--color-danger-300);
} 

/* default outlined */
.Button_button__1EPUx.Button_outlined__3-e93 {
  background-color: var(--color-transparent);
}
/* outlined hover, active, focus */
.Button_button__1EPUx.Button_outlined__3-e93:hover, 
.Button_button__1EPUx.Button_outlined__3-e93.Button_active__2tsBs, 
.Button_button__1EPUx.Button_outlined__3-e93:focus {
  background-color: var(--color-ash-100);
}

/* active, focus */
.Button_button__1EPUx.Button_active__2tsBs,
.Button_button__1EPUx:focus,
.Button_button__1EPUx.Button_outlined__3-e93.Button_active__2tsBs, 
.Button_button__1EPUx.Button_outlined__3-e93:focus {
  outline: none;
  box-shadow: 0 0 0 1pt var(--color-dark-300);
  border-color: var(--color-ash-100) !important;
}

/* default disabled */
.Button_button__1EPUx.Button_disabled__21T4o, .Button_button__1EPUx.Button_disabled__21T4o:hover {
  background-color: var(--color-ash-400);
  border-color: var(--color-ash-400);
  color: var(--color-dark-500);
}
/* default outlined disabled */
.Button_button__1EPUx.Button_disabled__21T4o.Button_outlined__3-e93, .Button_button__1EPUx.Button_disabled__21T4o.Button_outlined__3-e93:hover {
  background-color: var(--color-ash-100);
  border-color: var(--color-ash-500);
  color: var(--color-dark-500);
}

.Button_button__1EPUx.Button_loading__2e4SQ {}

.UploadPage_UploadPage__LZu2R {}

.UploadPage_UploadPageForm__3mraU {
  padding: 24px 0;
}
.Text_subtitle__2aBGH {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.Text_subtitle__2aBGH.Text_s1__BRmQL {
  font-size: 15px;
} 

.Text_subtitle__2aBGH.Text_s2__38j-L {
  font-size: 13px;
} 

.Text_paragraph__3eye- {
  font-style: normal;
  font-weight: 400;
}

.Text_paragraph__3eye-.Text_p1__3Eo61 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.Text_paragraph__3eye-.Text_p2__3D6BA {
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
}

.Text_caption__1-OnR {
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

.Text_caption__1-OnR.Text_c1__mRidS {
  font-weight: normal;
}

.Text_caption__1-OnR.Text_c2__1E-DY {
  font-weight: 600;
}

.Text_label__1x8qq {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.FileSelector_FileSelector__QgACG {
}

.FileSelector_FileSelectorDropZone__1eHXG {
  background: var(--color-ash-200);
  border: 2px dashed var(--color-ash-500);
  box-sizing: border-box;
  border-radius: 4px;
}

.FileSelector_FileSelectorContainer__HXljT {
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FileSelector_FileSelectorContainer__HXljT > img {
  margin-bottom: 16px;
}

.FileSelector_FileSelectorContainer__HXljT > button {
  margin-bottom: 8px;
}

.FileSelector_FileSelectorDropZone__1eHXG.FileSelector_dragActive__qSV_a {
  border-color: var(--color-brand-200);
}
.FileSelector_FileSelectorDropZone__1eHXG.FileSelector_dragActive__qSV_a > .FileSelector_FileSelectorContainer__HXljT {
  opacity: 0.3;
}

.FilePreview_FilePreview__1B4ps {
  width: 50%;
  background: #F5F5F5;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FilePreview_FilePreview__1B4ps.FilePreview_success__aU5WL,
.FilePreview_FilePreview__1B4ps.FilePreview_error__NBXAk,
.FilePreview_FilePreview__1B4ps.FilePreview_loading__VFET8 {
  padding-bottom: 24px;
}

.FilePreview_FilePreview__1B4ps.FilePreview_error__NBXAk {
  border: 1px dashed var(--color-danger-300);
  border-radius: 4px;
}

.FilePreview_FilePreview__1B4ps.FilePreview_success__aU5WL {
  border: 1px dashed var(--color-success-300);
  border-radius: 4px;
}

.FilePreview_FilePreviewContent__14fw3 {
  flex-grow: 1;
}

.FilePreview_FilePreviewContent__14fw3 .FilePreview_lastModified__33Qeq {
  color: var(--color-dark-500);
}

.FilePreview_FilePreviewContent__14fw3 .FilePreview_errorMessage__1zXIG {
  color: var(--color-danger-300);
}

.FilePreview_FilePreviewContent__14fw3 .FilePreview_successMessage__2GrM5 {
  color: var(--color-success-300);
}

.FilePreview_FilePreviewContent__14fw3 .FilePreview_loadingMessage__3LqFF {
  color: var(--color-brand-300);
}

.FilePreview_FilePreviewCancelButton__2Ouky {
  padding: 0;
  width: 16px;
  height: 16px;
  background: url(/static/media/cancel.97cd8acb.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 0;
  border: none;
}

