:root {
  --color-transparent: transparent;

  --color-dark-100: #000000;
  --color-dark-200: #262626;
  --color-dark-300: #434343;
  --color-dark-400: #555555;
  --color-dark-500: #7B7B7B;

  --color-ash-100: #FFFFFF;
  --color-ash-200: #F5F5F5;
  --color-ash-300: #E9E9E9;
  --color-ash-400: #D9D9D9;
  --color-ash-500: #C4C4C4;

  --color-brand-100: #E5CAF3;
  --color-brand-200: #5A3EAF;
  --color-brand-300: #3A0CA3;
  --color-brand-400: #271891;
  --color-brand-500: #000A67;

  --color-success-100: #E4F4F5;
  --color-success-200: #C1EBEB;
  --color-success-300: #69BEBD;
  --color-success-400: #37828C;
  --color-success-500: #103034;

  --color-warning-100: #F9EBDC;
  --color-warning-200: #F7C693;
  --color-warning-300: #E89753;
  --color-warning-400: #B45C2B;
  --color-warning-500: #441809;

  --color-danger-100: #F8CDBE;
  --color-danger-200: #FF8162;
  --color-danger-300: #D82400;
  --color-danger-400: #9E1B00;
  --color-danger-500: #4F0E00;

  --color-info-100: #EDF5F9;
  --color-info-200: #BCDFF7;
  --color-info-300: #2A6FB5;
  --color-info-400: #1E4E86;
  --color-info-500: #031427;
}