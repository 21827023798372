@import './resources/css/colors.css';
@import './resources/css/fonts.css';
@import './resources/css/typography.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-theme-800)
}
