@import "../../../resources/css/colors.css";

.button {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  line-height: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: var(--color-ash-100);
  display: inline-flex;
  align-items: center;
  justify-content : center;
  border: 1px solid var(--color-transparent);
}

.sm {
  min-width: 89px;
  height: 32px;
}
.md {
  min-width: 107px;
  height: 40px;
}
.lg {
  min-width: 89px;
  height: 48px;
}

/* default brand */
.button.brand {
  background-color: var(--color-brand-300);
}
/* brand button hover, active, focus */
.button.brand:hover, 
.button.brand.active, 
.button.brand:focus {
  background-color: var(--color-brand-200);
}
/* default brand outlined */
.button.brand.outlined {
  color: var(--color-brand-300);
  border-color: var(--color-brand-300);
}
/* brand button outlined hover, active, focus */
.button.brand.outlined:hover,
.button.brand.outlined.active, 
.button.brand.outlined:focus {
  color: var(--color-ash-100);
  background-color: var(--color-brand-300);
}

/* default success */
.button.success {
  background-color: var(--color-success-400);
}
/* success button hover, active, focus */
.button.success:hover,
.button.success.active,
.button.success:focus {
  background-color: var(--color-success-300);
}
/* default success outlined */
.button.success.outlined {
  color: var(--color-success-400);
  border-color: var(--color-success-400);
}
/* success outlined hover, active, focus */
.button.success.outlined:hover,
.button.success.outlined.active, 
.button.success.outlined:focus {
  color: var(--color-ash-100);
  border-color: var(--color-transparent);
  background-color: var(--color-success-400);
} 

/* default danger */
.button.danger {
  background-color: var(--color-danger-300);
}
/* danger hover, active, focus */
.button.danger:hover, 
.button.danger.active, 
.button.danger:focus {
  background-color: var(--color-danger-200);
}
/* default danger outlined */
.button.danger.outlined {
  color: var(--color-danger-300);
  border-color: var(--color-danger-300);
}
/* danger outlined hover, active, focus */
.button.danger.outlined:hover, 
.button.danger.outlined.active, 
.button.danger.outlined:focus {
  color: var(--color-ash-100);
  border-color: var(--color-transparent);
  background-color: var(--color-danger-300);
} 

/* default outlined */
.button.outlined {
  background-color: var(--color-transparent);
}
/* outlined hover, active, focus */
.button.outlined:hover, 
.button.outlined.active, 
.button.outlined:focus {
  background-color: var(--color-ash-100);
}

/* active, focus */
.button.active,
.button:focus,
.button.outlined.active, 
.button.outlined:focus {
  outline: none;
  box-shadow: 0 0 0 1pt var(--color-dark-300);
  border-color: var(--color-ash-100) !important;
}

/* default disabled */
.button.disabled, .button.disabled:hover {
  background-color: var(--color-ash-400);
  border-color: var(--color-ash-400);
  color: var(--color-dark-500);
}
/* default outlined disabled */
.button.disabled.outlined, .button.disabled.outlined:hover {
  background-color: var(--color-ash-100);
  border-color: var(--color-ash-500);
  color: var(--color-dark-500);
}

.button.loading {}
